<template>
  <div>
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <b-row>
          <b-col
            sm="12"
            md="12"
          >
            <i2-form-input
              v-model="model.name"
              rules="required"
              label="Nome"
              placeholder="informe o nome do cliente"
              name="name"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12"
            md="12"
          >
            <i2-form-input
              v-model="model.email_google_user"
              rules="required|email"
              label="Email Usuário Google"
              placeholder="informe o email do usuário admin no google"
              name="email"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12"
            md="12"
          >
            <i2-form-input
              v-model="model.index_name"
              rules="required"
              label="Nome do Índice"
              placeholder="Nome do índice que será utilizado."
              name="email"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <organization-select v-model="model.monitoring_ou"
                                 :client="modelUuid"
                                 name="ou"
                                 label="Unidade Furtados/Monitoramento"
                                 :multiple="false" :show-label="true"
            />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <div>
      <b-row>
        <b-col
          sm="12"
          md="12"
          class="text-right"
        >

          <!-- Form Actions -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="button"
            class="mr-2"
            @click="onSubmit"
          >

            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Salvar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-danger"
            type="button"
            @click="onCancel"
          >

            <font-awesome-icon
              prefix="far"
              :icon="['far', 'times-circle']"
              class="mr-50"
            />
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { alphaNum, email, required } from '@validations'
import { ValidationObserver } from 'vee-validate'
import OrganizationSelect from '@/components/app/Organization/OrganizationSelect.vue'

export default {
  name: 'ClientForm',
  directives: {
    Ripple,
  },
  components: {
    OrganizationSelect,
    ValidationObserver,
  },
  props: {
    modelUuid: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      model: {
        name: '',
        uuid: null,
        monitoring_ou: '',
      },
    }
  },
  watch: {
    modelUuid(data) {
      if (data === '') {
        return
      }
      this.getData(data)
    },
  },
  methods: {
    onSubmit() {
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          let response = {}
          const data = { ...this.model }
          if (this.model.uuid) {
            response = await this.$http.put(`clients/${this.model.uuid}`, data)
          } else {
            response = await this.$http.post('clients', data)
          }
          if (response.error) {
            this.$notify.error(response.error_message)
            return
          }
          this.$notify.success('Cliente salvo com sucesso.')
          this.$refs.refFormObserver.reset()
          this.model = {
            name: '',
            uuid: null,
          }
          this.$emit('onModelSave', true)
        }
      })
    },
    onCancel() {
      this.$emit('onCancel', true)
      this.model = { ...this.blankUser }
      this.$refs.refFormObserver.reset()
    },
    async getData(uuid) {
      const response = await this.$http.get(`clients/${uuid}`)
      if (!response.error) {
        this.model = response
        this.model.monitoring_ou = response.monitoring_ou?.uuid || null
      }
    },
  },
}
</script>

<style scoped>

</style>
